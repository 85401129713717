@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

:root {
  --primary-color: #0E76FD;
  --secondary-color: #0B5ED7;
  --accent-color: #D94032;
  --background-light: #f5f7fa;
  --background-dark: #1a1c20;
  --text-light: #333333;
  --text-dark: #e1e1e1;
}

body {
  margin: 0;
  font-family: 'VT323', monospace;
  transition: background-color 0.3s, color 0.3s;
}

body.light-mode {
  background-color: var(--background-light);
  color: var(--text-light);
}

body.dark-mode {
  background-color: var(--background-dark);
  color: var(--text-dark);
}

.App {
  text-align: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.chart-container {
  width: 100%;
  height: 400px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.main-content {
  flex-grow: 1;
  padding: 2rem 0;
  min-height: calc(100vh - 120px);
}

.header {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(224, 224, 224, 0.5);
  padding: 1rem 0;
}

.header a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.header a:hover {
  color: var(--secondary-color);
}

.footer {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(224, 224, 224, 0.5);
  padding: 1rem 0;
  text-align: center;
}

.btn-primary {
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-family: 'VT323', monospace;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 25px;
}

.btn-primary:hover {
  opacity: 0.9;
  transform: scale(1.05);
}

input, select {
  font-family: 'VT323', monospace;
  padding: 0.5rem;
  border: 1px solid rgba(204, 204, 204, 0.5);
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
}

.prediction-form {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.recent-predictions {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.recent-predictions ul {
  list-style-type: none;
  padding: 0;
}

.recent-predictions li {
  padding: 0.5rem 0;
  border-bottom: 1px solid rgba(224, 224, 224, 0.5);
  transition: transform 0.3s ease;
}

.recent-predictions li:hover {
  transform: translateX(5px);
}

.recent-predictions li:last-child {
  border-bottom: none;
}

@media (max-width: 768px) {
  body {
    font-size: 16px;
  }
  
  .header, .footer {
    padding: 0.5rem 0;
  }
  
  .main-content {
    padding: 1rem;
  }
}

.dark-mode .header,
.dark-mode .footer,
.dark-mode .prediction-form,
.dark-mode .recent-predictions,
.dark-mode .chart-container {
  background: rgba(0, 0, 0, 0.2);
}

.dark-mode input,
.dark-mode select {
  background: rgba(0, 0, 0, 0.2);
  color: var(--text-dark);
}